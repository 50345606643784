import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppInfoService } from './app-info.service';

@NgModule({
    providers: [],
})
export class AppInfoModule {
    public static forRoot(): ModuleWithProviders<AppInfoModule> {
        return {
            ngModule: AppInfoModule,
            providers: [AppInfoService],
        };
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'environment/environment';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';

import { ErrorMessage } from '@priva/appshell';
import { CurrentUser } from '@priva/auth/current-user';

import { TenantUserDto } from 'app/core/models';

import { AppActions } from '../actions';
import { AppFacade } from '../facade';

@Injectable()
export class TenantUsersEffect {
    constructor(
        private actions$: Actions,
        private httpClient: HttpClient,
        private appFacade: AppFacade,
    ) {}

    private readonly accessControlServiceUrl = `${environment.configuration.uris.accessControlService}`;
    private readonly SERVICE_NAME = 'analytics-building';
    private readonly FEATURE_NAME = 'view-reports';

    loadTenantUserList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.loadTenantUserList),
            withLatestFrom(this.appFacade.currentUser$),
            switchMap(([_action, currentUser]: [any, CurrentUser | null]) => {
                const url = `${this.accessControlServiceUrl}/${currentUser?.tenantId}/users?serviceName=${this.SERVICE_NAME}&featureName=${this.FEATURE_NAME}`;

                return this.httpClient.get<TenantUserDto[]>(url).pipe(
                    map((tenantUsers: TenantUserDto[]) =>
                        AppActions.loadTenantUserListSuccess({ tenantUsers }),
                    ),
                    catchError((error: ErrorMessage) => of(AppActions.loadTenantUserListError({ error }))),
                );
            }),
        ),
    );
}

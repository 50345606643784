import { CurrentUser } from '@priva/auth/current-user';
import { DynamicComponent } from '@priva/components/dynamic';

import { TenantUserDto, ToasterNotification } from 'app/core/models';

import { AppError } from '../app.model';

export interface AppState {
    error?: AppError;
    activeDialogs: DynamicComponent[];
    activePanel?: DynamicComponent;
    notification?: ToasterNotification;
    throbbers: { [key: string]: string };
    currentUser: CurrentUser | null;
    tenantUsers: TenantUserDto[];
    isTenantUsersLoaded: boolean;
}

export const initialAppState: AppState = {
    activeDialogs: [],
    throbbers: {},
    currentUser: null,
    tenantUsers: [],
    isTenantUsersLoaded: false,
};

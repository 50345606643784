import { Action, createReducer, on } from '@ngrx/store';

import { ErrorMessage } from '@priva/appshell';

import { OperationTimeDto, SiteAsset } from 'app/core/models';

import { SiteActions } from '../actions';

export interface SiteState {
    sites: SiteAsset[];
    isSitesLoaded: boolean;
    sitesError: ErrorMessage | null;
    operationTime: OperationTimeDto | null;
    isOperationTimeLoaded: boolean;
    operationTimeError: ErrorMessage | null;
}

export const initialState: SiteState = {
    sites: [],
    isSitesLoaded: false,
    sitesError: null,
    operationTime: null,
    isOperationTimeLoaded: false,
    operationTimeError: null,
};

const reducer = createReducer<SiteState>(
    initialState,
    on(SiteActions.getSitesSuccess, (state, action): SiteState => {
        const sortedSites = [...action.sites].sort((a, b) => a.name.localeCompare(b.name));
        return {
            ...state,
            sites: sortedSites,
            isSitesLoaded: true,
            sitesError: null,
        };
    }),

    on(SiteActions.getSitesError, (state, action): SiteState => {
        return {
            ...state,
            sites: [],
            isSitesLoaded: true,
            sitesError: action.error,
        };
    }),

    on(SiteActions.getOperationTimeSuccess, (state, action): SiteState => {
        return {
            ...state,
            operationTime: action.operationTime,
            isOperationTimeLoaded: true,
            operationTimeError: null,
        };
    }),

    on(SiteActions.getOperationTimeError, (state, action): SiteState => {
        return {
            ...state,
            operationTime: null,
            isOperationTimeLoaded: true,
            operationTimeError: action.error,
        };
    }),
);

export function siteReducer(state: SiteState | undefined, action: Action) {
    return reducer(state, action);
}

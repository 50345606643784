import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppInfo } from './app-info.interface';

@Injectable()
export class AppInfoService {
    constructor(private http: HttpClient) {}

    public get appInfo$(): Observable<AppInfo> {
        return this.http.get<AppInfo>('./assets/help/product.json');
    }
}

export enum HttpError {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    TimeOut = 408,
    Conflict = 409,
    TooManyRequests = 429,
    InternalServerError = 500,
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { AppConfigurationService } from 'app/core/configuration/app-configuration.service';
import { FeatureToggleDto } from 'app/core/models/feature-toggle.model';

import { AppActions } from '../actions';

@Injectable({
    providedIn: 'root',
})
export class AppApiEffects {
    private actions$ = inject(Actions);
    private httpClient = inject(HttpClient);
    private appConfigurationService = inject(AppConfigurationService);

    private featureToggleUrl = this.appConfigurationService.configuration.uris.featureTogglesService;

    loadFeatureToggles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.loadCurrentUserSuccess),
            map(() => AppActions.loadFeatureToggles()),
        ),
    );

    loadFeatureTogglesSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.loadFeatureToggles),
            switchMap(() =>
                this.httpClient.get<FeatureToggleDto[]>(this.featureToggleUrl).pipe(
                    map((featureToggles: FeatureToggleDto[]) =>
                        AppActions.loadFeatureTogglesSuccess({ featureToggles }),
                    ),
                    catchError((error) => of(AppActions.loadFeatureToggleError({ error }))),
                ),
            ),
        ),
    );
}

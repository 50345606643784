import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivaBffLoggedInUserGuard } from '@priva/auth/current-user';
import { PrivaErrorRouteUrls } from '@priva/error-pages';

import { AppMainComponent } from './app-main.component';
import { manageReportsGuard, redirectToV1Guard } from './guards';
import { restrictedAccessGuard } from './guards/app-restricted-access-guard';

const routes: Routes = [
    {
        path: PrivaErrorRouteUrls.Base,
        loadChildren: async () => (await import('./core/routing/error-pages.module')).AppErrorPagesModule,
    },
    {
        path: 'signout',
        loadChildren: async () => (await import('./core/routing/sign-out.module')).AppSignOutModule,
    },
    {
        path: 'bff/logout',
        loadChildren: async () => (await import('./core/routing/sign-out.module')).AppSignOutModule,
    },
    {
        path: '',
        canActivate: [PrivaBffLoggedInUserGuard, restrictedAccessGuard],
        component: AppMainComponent,
        children: [
            {
                path: 'home',
                loadChildren: async () =>
                    (await import('./report-overview/report-overview.module')).ReportOverviewModule,
            },
            {
                path: 'smart-insights',
                loadChildren: async () =>
                    (await import('./smart-insights/smart-insights.module')).SmartInsightsModule,
                canActivate: [manageReportsGuard],
            },
            {
                path: 'report/:reportId/report-edition/:editionId',
                loadChildren: async () =>
                    (await import('./empty-report-edition-redirect/empty-report-edition-redirect.module'))
                        .EmptyReportEditionRedirectModule,
                canActivate: [redirectToV1Guard],
            },
            { path: '', redirectTo: 'home', pathMatch: 'full' },
        ],
    },
    { path: '**', redirectTo: '/error/not-found', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}

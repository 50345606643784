import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { HelpInfo, HelpInfoService } from '@priva/appshell';

import { AppInfo, AppInfoService } from '../app-info';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsPrivaHelpInfoService implements HelpInfoService {
    private helpInfo$: Observable<HelpInfo>;

    constructor(private appInfoService: AppInfoService) {
        this.composeHelpInfo();
    }

    public get helpInfo(): Observable<HelpInfo> {
        return this.helpInfo$;
    }

    private composeHelpInfo() {
        this.helpInfo$ = this.appInfoService.appInfo$.pipe(map((appInfo) => this.compileHelp(appInfo)));
    }

    private compileHelp(appInfo: AppInfo): HelpInfo {
        const helpInfo: HelpInfo = {
            about: {
                items: [],
            },
            support: {
                description: ' ',
                items: [
                    {
                        links: [
                            {
                                title: 'APP.HELP.SUPPORT.TITLE', // 'Help Center'
                                uri: appInfo.application.supportUrl,
                            },
                        ],
                    },
                ],
            },
        };

        if (appInfo) {
            const applVersionRelease = `${appInfo.application.version}.${appInfo.application.release}`;
            helpInfo.about?.items?.push(
                {
                    title: 'APP.HELP.APPLICATIONTYPE', // 'Application type'
                    description: `${appInfo.application.name} ${applVersionRelease}`,
                },
                {
                    title: 'APP.HELP.ANALYTICS.VERSION', // 'Application version'
                    description: appInfo.application.appVersion,
                },
            );
        }

        return helpInfo;
    }
}

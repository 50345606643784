import { Component, OnDestroy, OnInit } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { AppShellAreaService, AppShellAreaType } from '@priva/appshell';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { PrivaLocalizationService } from '@priva/localization';

import { AppError } from './app.model';
import { ErrorHandlerService } from './core/error/error-handling.service';
import { AppFacade } from './state/facade';

@Component({
    selector: 'analytics-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    public AppShellAreaType = AppShellAreaType;

    public hasNotificationBar = false;
    public hasPrimaryNav = true;
    public hasLoadedLanguage = false;

    private unsubscribe$: Subject<void> = new Subject();
    public error$: Observable<AppError | undefined>;
    public throbbers$: Observable<any>;

    constructor(
        private notificationService: PrivaNotificationsService,
        private appShellAreaService: AppShellAreaService,
        private errorHandler: ErrorHandlerService,
        private appFacade: AppFacade,
        private localizationService: PrivaLocalizationService,
    ) {
        this.error$ = this.appFacade.error$;
        this.throbbers$ = this.appFacade.throbbers$;
    }

    public ngOnInit() {
        /* TODO: remove after localization service update - should load with default language*/
        this.localizationService.language.pipe(first()).subscribe(() => {
            this.hasLoadedLanguage = true;
        });

        // Has notification bar
        this.notificationService.barVisibility
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));

        // Initialize ErrorHandler
        this.errorHandler.initialize();
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public dispatchAction(action: Action) {
        this.appFacade.dispatchAction(action);
    }
}

import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReplaySubject, Subject } from 'rxjs';

import { PrimaryNavigationService, PrivaNavigationItem } from '@priva/appshell';

import { ApplicationToggles } from 'app/app-features.enum';
import { AppFacade } from 'app/state/facade';

@Injectable()
export class AppPrimaryNavigationService implements PrimaryNavigationService {
    private appFacade = inject(AppFacade);
    private destroyRef = inject(DestroyRef);
    private navigation$: Subject<PrivaNavigationItem[]> = new ReplaySubject();

    constructor() {
        this.appFacade
            .hasCurrentUserFeature(ApplicationToggles.betaSmartInsights)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((hasBetaSmartInsights: boolean) => {
                if (hasBetaSmartInsights) {
                    this.navigation.next([
                        {
                            label: 'APP.NAVIGATION.CUSTOM_REPORTS',
                            label_short: 'APP.NAVIGATION.CUSTOM_REPORTS',
                            icon: 'fa-regular fa-chart-user',
                            slug: 'custom-reports',
                            url: '/home',
                        },
                        {
                            label: 'APP.NAVIGATION.SMART_INSIGHTS',
                            label_short: 'APP.NAVIGATION.SMART_INSIGHTS',
                            icon: 'fa-regular fa-microchip',
                            slug: 'smart-insights',
                            url: '/smart-insights',
                        },
                    ]);
                } else {
                    this.navigation.next([]);
                }
            });
    }

    public get navigation(): Subject<PrivaNavigationItem[]> {
        return this.navigation$;
    }
}

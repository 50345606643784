import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { HelpInfoService } from '@priva/appshell';
import { PrivaUtilitiesModule } from '@priva/utilities';

import { AnalyticsPrivaHelpInfoService } from './help.service';

@NgModule({
    imports: [CommonModule, PrivaUtilitiesModule],
    declarations: [],
    providers: [],
    exports: [],
})
export class PrivaHelpInfoModule {
    public static forRoot(): ModuleWithProviders<PrivaHelpInfoModule> {
        return {
            ngModule: PrivaHelpInfoModule,
            providers: [{ provide: HelpInfoService, useClass: AnalyticsPrivaHelpInfoService }],
        };
    }
}

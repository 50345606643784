import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';

import { AppConfigurationService } from './app-configuration.service';

@Injectable()
export class AppConfigurationResolver {
    private configSubject: ReplaySubject<boolean>;

    constructor(
        private appConfiguration: AppConfigurationService,
        private router: Router,
    ) {
        this.configSubject = new ReplaySubject<boolean>(1);
    }

    public resolve(): Observable<boolean> {
        this.appConfiguration.configurationLoaded.subscribe((loaded: boolean) => {
            if (!loaded) {
                this.router.navigate(['error/not-authorized']);
            }
            this.configSubject.next(loaded);
            this.configSubject.complete();
        });
        this.appConfiguration.loadConfiguration();

        return this.configSubject.asObservable();
    }
}

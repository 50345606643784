import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { CurrentUser, PrivaAuthCurrentUserService } from '@priva/auth/current-user';
import { NotificationStatusEnum, PrivaNotificationsService } from '@priva/components/notifications';

import { FeatureToggleDto } from 'app/core/models/feature-toggle.model';

import { AppActions, SiteActions } from '../actions';
import { SiteState } from '../reducers';
import { isSitesLoaded } from '../selectors/site.selectors';

@Injectable({
    providedIn: 'root',
})
export class AppEffects {
    private router = inject(Router);
    private actions$: Actions = inject(Actions);
    private siteStore = inject(Store<SiteState>);
    private privaAuthCurrentUserService = inject(PrivaAuthCurrentUserService);
    private notificationService = inject(PrivaNotificationsService);

    public logOut$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.logOut),
                tap(() => this.router.navigate(['signout'])),
            ),
        { dispatch: false },
    );

    public routerNavigated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.event.url === '/' || action.payload.event.url.startsWith('/?'),
            ),
            map(() => AppActions.resetState()),
        ),
    );

    public loadCurrentUserAndSites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => !action.payload.routerState.url.startsWith('/error')),
            filter((action: RouterNavigatedAction) => {
                return (
                    action.payload.routerState.url.indexOf('/home') !== -1 ||
                    action.payload.routerState.url.indexOf('/smart-insights') !== -1
                );
            }),
            withLatestFrom(this.siteStore.select(isSitesLoaded)),
            switchMap(([_, areSitesLoaded]) => {
                const responseActions: Action[] = [AppActions.loadCurrentUser()];
                if (!areSitesLoaded) {
                    responseActions.push(SiteActions.getSites());
                }
                return responseActions;
            }),
        );
    });

    loadCurrentUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.loadCurrentUser),
            switchMap(() => {
                return this.privaAuthCurrentUserService.user.pipe(
                    take(1),
                    map((currentUser) => AppActions.loadCurrentUserSuccess({ currentUser })),
                    catchError((error) => of(AppActions.loadCurrentUserError({ error }))),
                );
            }),
        ),
    );

    updateCurrentUserWithFeatureToggles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.loadCurrentUserSuccess),
            switchMap(({ currentUser }) =>
                this.actions$.pipe(
                    ofType(AppActions.loadFeatureTogglesSuccess),
                    take(1),
                    map(({ featureToggles }) => {
                        const newCurrentUser = this.mergedFeatureToggles(currentUser, featureToggles);
                        return AppActions.updateCurrentUser({ currentUser: newCurrentUser });
                    }),
                ),
            ),
        ),
    );

    public showToaster$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.showToaster),
            tap(({ notification }) => {
                if (notification) {
                    switch (notification.status) {
                        case NotificationStatusEnum.Danger:
                            this.notificationService.toaster.error(notification.message);
                            break;
                        case NotificationStatusEnum.Info:
                            this.notificationService.toaster.info(notification.message);
                            break;
                        case NotificationStatusEnum.Success:
                            this.notificationService.toaster.success(notification.message);
                            break;
                        case NotificationStatusEnum.Warning:
                            this.notificationService.toaster.warning(notification.message);
                            break;
                    }
                }
            }),
            map(() => AppActions.removeToaster()),
        ),
    );

    private mergedFeatureToggles(currentUser: CurrentUser, featureToggles: FeatureToggleDto[]) {
        const existingFeatures = currentUser.features || [];
        const convertedFeatureToggles = (featureToggles || []).map((x) => ({
            name: x.technicalName,
            state: x.enabled,
        }));

        const newFeatures = convertedFeatureToggles.filter(
            (newFeature) =>
                !existingFeatures.some((existingFeature) => existingFeature.name === newFeature.name),
        );

        return {
            ...currentUser,
            features: [...existingFeatures, ...newFeatures],
        };
    }
}

import { effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { SiteAsset } from 'app/core/models';
import { SiteFacade } from 'app/state/facade';

export const restrictedAccessGuard: CanActivateFn = (_route, _state) => {
    const router = inject(Router);
    const siteFacade = inject(SiteFacade);
    const isSitesLoaded = toSignal(siteFacade.isSitesLoaded$);

    effect(() => {
        if (!isSitesLoaded()) {
            setTimeout(() => {
                siteFacade.getSites();
            });
        }
    });

    const navigateToNotFoundPage = () => {
        router.navigateByUrl('/error/not-found');
        return of(false);
    };

    const isAllowed = (sites: SiteAsset[]): boolean => {
        return sites.some((site) => site.permissions.manageReports || site.permissions.viewReports);
    };

    return siteFacade.isSitesLoaded$.pipe(
        filter((isLoaded) => isLoaded),
        switchMap(() => siteFacade.sites$),
        map((sites) => {
            if (isAllowed(sites)) {
                return true;
            } else {
                navigateToNotFoundPage();
                return false;
            }
        }),
        catchError(() => navigateToNotFoundPage()),
    );
};

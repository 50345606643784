import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'environment/environment';
import { catchError, map, of, switchMap } from 'rxjs';

import { ErrorMessage } from '@priva/appshell';

import { OperationTimeDto, SiteAsset } from 'app/core/models';

import { SiteActions } from '../actions';

@Injectable({
    providedIn: 'root',
})
export class SiteApiEffects {
    private actions$ = inject(Actions);
    private httpClient = inject(HttpClient);
    private readonly siteServiceUrl = `${environment.configuration.uris.siteService}`;

    getSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.getSites),
            switchMap(() => {
                return this.httpClient.get<SiteAsset[]>(this.siteServiceUrl).pipe(
                    map((sites: SiteAsset[]) => SiteActions.getSitesSuccess({ sites })),
                    catchError((error: ErrorMessage) => of(SiteActions.getSitesError({ error }))),
                );
            }),
        ),
    );

    getOperationTime$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.getOperationTime),
            switchMap((action) => {
                const url = `${this.siteServiceUrl}/${action.siteId}/operationhours`;
                return this.httpClient.get<OperationTimeDto>(url).pipe(
                    map((operationTime: OperationTimeDto) =>
                        SiteActions.getOperationTimeSuccess({ operationTime }),
                    ),
                    catchError((error: ErrorMessage) => of(SiteActions.getOperationTimeError({ error }))),
                );
            }),
        ),
    );
}

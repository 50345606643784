import { ActionReducerMap } from '@ngrx/store';

import { AppState } from '../app.state';
import { appReducer } from './app.reducer';
import { siteReducer, SiteState } from './site.reducer';

export interface RootState {
    app: AppState;
    site: SiteState;
}

export const rootReducer: ActionReducerMap<RootState> = {
    app: appReducer,
    site: siteReducer,
};

import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { ApplicationToggles } from 'app/app-features.enum';
import { AppFacade } from 'app/state/facade';

export const manageReportsGuard: CanActivateFn = (_route, _state) => {
    const router = inject(Router);
    const appFacade = inject(AppFacade);

    const navigateToNotFoundPage = () => {
        router.navigateByUrl('/error/not-found');
        return of(false);
    };

    const currentUser = toSignal(appFacade.currentUser$);
    if (!currentUser()) {
        appFacade.loadCurrentUser();
    }

    return appFacade.currentUser$.pipe(
        filter((currentUser) => currentUser !== null),
        switchMap((currentUser) => {
            if (currentUser) {
                return appFacade.hasCurrentUserFeature(ApplicationToggles.betaSmartInsights).pipe(
                    map((hasBetaSmartInsights) => {
                        if (hasBetaSmartInsights) {
                            return true;
                        } else {
                            navigateToNotFoundPage();
                            return false;
                        }
                    }),
                    catchError(() => {
                        return navigateToNotFoundPage();
                    }),
                );
            } else {
                return navigateToNotFoundPage();
            }
        }),
        catchError(() => {
            return navigateToNotFoundPage();
        }),
    );
};

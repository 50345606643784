import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AppConfigurationService } from '../core/configuration/app-configuration.service';

export const redirectToV1Guard: CanActivateFn = (route, _state) => {
    const appConfigurationService = inject(AppConfigurationService);
    const frontendWebV1Url = appConfigurationService.configuration.uris.frontendWebV1Url;

    const reportId = route.paramMap.get('reportId');
    const editionId = route.paramMap.get('editionId');

    if (reportId && editionId) {
        window.open(`${frontendWebV1Url}report/${reportId}/report-edition/${editionId}`, '_self');
    }

    return true;
};
